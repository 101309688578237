/* src/components/GameCoverModal.css */

/* Modal dimmed background */
.gamecover-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7); /* Dimmed background */
  z-index: 1000;
}

/* Modal box takes up 88% of the screen */
.modal-box {
  width: 88vw;
  height: 88vh;
  background: black;
  box-shadow: 0px 0px 48px 16px rgba(255, 255, 255, 0.16);
  border-radius: 40px;
  display: flex;
  overflow: hidden;
}

/* Image container takes up 40% of the modal's width */
.game-cover-img {
  width: 40%;
  height: 100%;
  object-fit: cover; /* Ensures image maintains aspect ratio */
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

/* Content container takes up 60% of the modal's width */
.text-content {
  width: 60%;
  padding: 24px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 48px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700;
}

.min-players {
  font-size: 24px;
  font-family: 'Noto Sans', sans-serif;
  color: #b3b3b3;
  margin-bottom: 16px;
}

.game-description {
  font-size: 24px;
  font-family: 'Noto Sans', sans-serif;
  margin-bottom: 24px;
}
