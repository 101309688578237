/* src/components/GameGrid.css */

.game-grid {
  display: grid;
  grid-auto-flow: column; /* Force grid items to flow in a single row */
  grid-template-columns: repeat(
    auto-fill,
    360px
  ); /* Ensure each item has a set width */
  gap: 24px; /* Space between items */
  justify-content: start; /* Align items to the start of the scrollable area */
  height: auto; /* Allow grid height to adjust based on content */
  width: max-content;
}
