.onboard-completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  justify-content: center;
  max-width: 640px;
  width: 88vw;
  margin: auto;
}

.onboard-completed .button {
  margin-top: 40px;
  font-size: 1.5rem;
  max-width: 640px;
  width: 88vw;
}

@media (max-width: 720px) {
  .onboard-completed {
    height: calc(
      100vh - 80px
    ); /* Subtract 80px from the full viewport height */
    margin-top: 80px; /* Add the fixed offset from the top */
    justify-content: start;
  }
  .onboard-completed .button {
    font-size: 1rem;
    min-height: 2.5rem;
  }
}
