.onboard-pick-content-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  justify-content: center;
}

@media (max-width: 720px) {
  .onboard-pick-content-style {
    height: calc(
      100vh - 80px
    ); /* Subtract 80px from the full viewport height */
    margin-top: 80px; /* Add the fixed offset from the top */
    justify-content: start;
  }
}
