/* Base styles for all forms */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px; /* Default gap between form elements */
  width: 100%;
  max-width: 480px;
  min-width: 240px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* For the text section at the top of the form */
.form-header-explainer {
  display: flex;
  flex-direction: column;
  gap: 0; /* No gap between the header and explainer */
}

.form-header {
  font-size: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

@media (max-width: 720px) {
  .form-header {
    font-size: 1.5rem;
  }
}

.form-explainer {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  color: #b3b3b3; /* Lighter color for the explainer text */
  max-width: 640px;
}

@media (max-width: 720px) {
  .form-explainer {
    font-size: 1rem;
    max-width: 88vw;
  }
}

/* MODIFIERS FOR EACH PAGE */
/* Modifier for the form in the Home page */
.form-container--home {
  padding-top: 48px; /* Additional top padding for the home form */
}

/* Modifier for custom content in the Room page */
.form-container--room {
  max-width: 320px; /* Ensure the form doesn't exceed 240px width */
  width: 100%; /* Allow it to shrink to fit smaller screens */
  margin: 0 auto; /* Center the form horizontally */
  padding-right: 40px;
}

.form-container--room .form-custom-content {
  font-size: 1.5rem; /* Adjust the size to be like an h2 */
  margin-bottom: 24px;
  text-align: center; /* Center the text if needed */
}

.form-container--room .button {
  max-width: 240px;
}

.form-container--emailverify .form-custom-content {
  margin-top: 0;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  width: 480px;
  max-width: 88vw;
  font-size: 1.5rem;
}

.form-container--email {
  width: 480px;
  max-width: 88vw;
}

/* Fullscreen version of the form container to be used for game responses */
.form-container--gameresponse {
  display: flex;
  flex-direction: column; /* Stack form elements vertically */
  justify-content: flex-end; /* Push content to the bottom */
  padding-bottom: 24vh; /* Push up by 24% of viewport height */
  align-items: center; /* Center horizontally */
  height: 100vh; /* Full viewport height */
  width: 88vw;
  gap: 16px; /* Maintain gap between form elements */
  box-sizing: border-box; /* Ensure padding is included in width/height */
}

.form-container--game-over {
  justify-content: flex-end; /* Push content to the bottom */
  margin-top: 16vh;
  margin-bottom: 16vh;
}

.form-container--replay-or-exit-modal {
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.form-qr-code {
  text-align: center;
}

.form-qr-image {
  max-width: 100%;
  width: 240px;
  height: auto;
  display: inline-block;
}
