/* Accordion container */
.accordion {
  margin-top: 24px;
  margin-bottom: 40px;
  color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  border: 1px solid #414141;
  max-width: 480px;
  width: 88vw;
}

/* Style all non-open headers when any section is open */
.accordion.has-open-section .accordion-header:not(.open) {
  opacity: 0.8;
  transition: all 0.4s ease;
}

/* Accordion section */
.accordion-section {
  border-bottom: 1px solid #414141;
}

.accordion-section:last-child {
  border-bottom: none;
}

/* Accordion header */
.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.accordion-header.open .accordion-title {
  margin: 0 auto;
  transform: translateX(12px);
}

.accordion-header-right {
  display: flex;
  align-items: center;
  min-width: 24px;
}

.accordion-header:hover {
  background-color: rgba(65, 65, 65, 0.64);
}

.accordion-header.open {
  background-color: rgba(65, 65, 65, 1);
}

.accordion-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.accordion-header-right {
  display: flex;
  align-items: center;
}

.selected-option {
  margin-right: 12px;
  color: #b3b3b3;
  font-size: 1.5rem;
}

/* Accordion content */
.accordion-content {
  border-top: 1px solid #414141;
}

/* Option item */
.accordion-option {
  padding: 12px 16px;
  cursor: pointer;
  transition:
    background-color 0.4s,
    color 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-content {
  text-align: center;
  max-width: 80%;
}

.accordion-option:hover {
  background-color: rgba(65, 65, 65, 0.64);
}

.accordion-option.selected {
  color: #00ff2f;
}

.option-label {
  font-weight: 400;
  font-size: 1.5rem;
}

.option-description {
  font-size: 1.2rem;
  margin-top: 2px;
  color: #b3b3b3;
}

.accordion-option.selected .option-description {
  color: #008218;
  /* Maintains same luminance relationship to #00ff2f as secondary gray to white */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .accordion-header,
  .accordion-option {
    padding: 14px 12px;
  }

  .accordion-title {
    font-size: 1.2rem;
  }

  .selected-option {
    font-size: 1.2rem;
  }

  .option-label {
    font-size: 1.2rem;
  }

  .option-description {
    font-size: 1rem;
  }

  .option-content {
    font-size: 1rem;
  }
}
