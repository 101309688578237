/* src/components/GameCover.css */

.game-cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px; /* Smooth corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 8px;
  height: 100%; /* Ensure each item respects the grid height */
  width: 360px; /* Fixed width for consistent sizing */
  cursor: pointer;
  transition: transform 0.2s ease; /* Animation for interaction */
}

.game-cover:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.game-cover img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
  border-radius: 4px; /* Slight rounding on the image */
  margin-bottom: 8px; /* Space between image and text */
}

.game-cover p {
  margin-top: 8px;
  margin-bottom: 8px;
  height: 72px;
  font-size: 24px;
  color: white;
  font-family: 'Noto Sans', sans-serif; /* Consistent font */
  text-align: center;
  justify-content: flex-start;
  cursor: pointer;
  max-width: 344px; /* Set max width for wrapping */
  white-space: normal; /* Allows normal wrapping behavior */
  word-break: keep-all; /* Prevents breaking words */
  overflow-wrap: normal; /* Keeps words intact */
}
