.audio-display {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-control {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10; /* Higher z-index to appear on top */
}

.audio-analyzer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
