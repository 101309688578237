.hamburger-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100; /* Base level */
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.2s,
    opacity 0.2s ease;
}

.hamburger-menu.open {
  visibility: visible;
  opacity: 1;
}

.hamburger-menu.closed {
  visibility: hidden;
  opacity: 0;
}

.menu-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.menu-content {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  max-width: 100%;
  height: 100%;
  background: #414141;
  box-shadow: -8px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%); /* Start off-screen to the right */
  transition: transform 0.8s ease;
}

.menu-content ul {
  list-style: none;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 96px;
}

.menu-content li {
  margin: 24px 0;
}

.menu-content a {
  text-decoration: none;
  color: #fff;
  font-size: 2.5rem;
  display: block;
  z-index: 200; /* Second level */
}

.menu-content a:hover {
  color: #d0ff00;
}

.hamburger-menu.open .menu-content {
  transform: translateX(0); /* Slide into view */
}

.hamburger-menu.closed .menu-content {
  transform: translateX(100%); /* Slide out of view */
}

.main-menu-hamburger-xmark {
  position: fixed;
  top: 24px;
  right: 24px;
  display: block; /* Prevent inline-block spacing issues */
  z-index: 300; /* Ensure it stays above other content */
  cursor: pointer;
}

/* Media Query for Mobile Screens */
@media (max-width: 720px) {
  .menu-content {
    width: 100vw; /* Full width of the viewport */
    max-width: 100vw; /* Ensure it doesn’t exceed viewport width */
  }

  .menu-content a {
    font-size: 1.5rem;
    display: block;
  }

  .menu-content ul {
    margin-top: 80px;
  }
}
