/* Muted.css */

.muted-icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.muted-icon-button:hover {
  transform: scale(1.05);
}

.muted-icon {
  width: 100%;
  height: 100%;
}
