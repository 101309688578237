/* Color Circles */

.color-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  flex: 0 0 auto; /* Prevent shrinking and ensure fixed width */
  transition: transform 0.2s ease-in-out; /* Smooth transition */
  margin: 4px;
}

.color-circle-selected {
  transform: scale(1.1); /* Slightly larger size */
  border: 2px solid #ffffff; /* Add a white border for better visibility */
}

.color-grid-container {
  display: flex;
  min-width: 240px;
  width: 480px;
  max-width: 88vw;
  height: 48px;
  align-items: center;
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 48px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* Hide the default scrollbar */
.color-grid-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit-based browsers */
}
