/* Player container layout */
.player-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.player-page-form {
  min-width: 240px;
  width: 480px;
  max-width: 88vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.player-page-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-to-game-link {
  display: flex;
  margin-top: 40px;
  font-size: 1.5rem;
  cursor: pointer;
}

.button-wrapper-go-playing .button {
  color: #00d0ff;
  padding-top: 16px;
}
