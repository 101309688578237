.choice-list {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 640px;
  width: 88vw;
}

.choice-option {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 8px;
  background-color: #d0ff00;
  color: black;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  transition: opacity 0.3s ease;
  min-height: 2.5rem;
  padding: 8px;
  font-size: 1.5rem;
}

.choice-option.selected {
  background-color: #00ff2f;
  color: black;
  cursor: not-allowed;
  pointer-events: none;
}

.choice-option.disabled {
  background-color: #828282;
  color: black;
  cursor: not-allowed;
  pointer-events: none;
}

.choice-option:hover {
  opacity: 0.8;
}

@media (max-width: 720px) {
  .choice-option:hover {
    opacity: 1;
  }
  .choice-option {
    font-size: 1rem;
  }
}
