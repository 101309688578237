.room-layout {
  display: flex;
  justify-content: space-between; /* Distribute space between child elements */
  align-items: center; /* Center child elements vertically */
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width of the parent */
  padding: 0 24px; /* Add padding to ensure space around edges */
  box-sizing: border-box; /* Include padding in width calculations */
  gap: 40px;
}

.room-form-container {
  flex: 0 0 33%; /* Ensure the form takes up 1/3 of the available space */
}

.room-players-container {
  flex: 0 0 66%; /* Ensure the players container takes up 2/3 of the available space */
  display: grid; /* Enables grid-based layout for player cards */
  align-items: center; /* Aligns the player cards vertically in the center */
  justify-items: center; /* Centers player cards horizontally */
}

.room-game-headline {
  position: fixed; /* Keep the game name in a fixed position */
  left: 24px; /* Align to the left */
  top: 24px; /* Adjust this as needed to avoid overlap with the wordmark */
  z-index: 1000; /* Ensure it stays above other content */
  margin: 0; /* Remove default margin */
}

.room-go-back {
  margin-top: 80px;
  left: 24px;
  position: fixed;
  cursor: pointer;
}

.room-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-wrapper-go-game .button {
  color: #00d0ff;
  min-width: 240px;
  padding-right: 40px;
  padding-top: 16px;
}
