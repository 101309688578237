/* src/components/Wordmark.css */

.wordmark {
  position: fixed; /* Keep the logo in a fixed position */
  left: 24px;
  top: 24px;
  display: block; /* Prevent inline-block spacing issues */
  z-index: 1000; /* Ensure it stays above other content */
}

.wordmark img {
  height: 64px; /* Consistent height for the image */
  width: auto; /* Maintain aspect ratio */
}

@media (max-width: 720px) {
  .wordmark img {
    height: 32px;
  }
}
