.onboard-pricing {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 24vh;
}

.stripe-wrapper {
  max-width: 640px; /* Limits width to prevent layout shift */
  width: 100%;
  text-align: center;
}

@media (max-width: 720px) {
  .onboard-pricing {
    height: calc(
      100vh - 80px
    ); /* Subtract 80px from the full viewport height */
    margin-top: 80px; /* Add the fixed offset from the top */
    justify-content: start;
    align-items: start;
  }
}
