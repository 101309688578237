/* src/pages/Home.css */

.home-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 80%;
  height: auto;
}

/* Media query for smaller screens */
@media (max-width: 720px) {
  .home-container {
    grid-template-columns: 1fr; /* Switch to a single column layout */
    grid-template-rows: auto auto; /* Stack elements vertically */
  }

  .home-container .form-container {
    padding: 24px; /* Adjust padding for smaller screens */
  }

  .home-container .logo {
    max-width: 64%; /* Make the logo smaller on mobile */
    padding-top: 24px; /* Add space above the logo */
  }
}
