/* Base styles for all progress bars */
.progress-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Base positioning */
}

/* Wrapper for progress bar content */
.progress-bar-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Checkmark for 100% progress */
.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  color: #00ff2f;
  font-weight: bold;
}

/* Timer */
.timer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.timer-pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  animation: timerpulse 1s infinite;
}

/* --- Scoped Styles for Onboarding --- */
.progress-bar-container.onboarding {
  width: 100%;
  height: 100%;
}

/* --- Scoped Styles for Avatar Score --- */
.progress-bar-container.avatar-score {
  /* Overlay on top of the avatar */
  position: absolute;
  top: 50%;
  left: 50%;
  /* Center over the avatar and rotate 90deg left (counterclockwise) */
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes timerpulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    color: #ffffff;
    opacity: 0.8;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.08);
    color: #ff0050;
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    color: #ffffff;
    opacity: 0.8;
  }
}

/* --- Responsive Adjustments --- */
@media (max-width: 720px) {
  .checkmark {
    font-size: 16px; /* Smaller font size for smaller screens */
  }
}
