.player-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
}

.player-name {
  width: 100%;
  align-items: center;
  justify-items: center;
  margin: 0;
}
