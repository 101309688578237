.avatar-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* New container for the SVG itself */
.avatar-icon {
  position: relative; /* allows the SVG to stay in place */
  width: 100%;
  height: 100%;
}

/* Visibility Classes */
.visible {
  display: inline;
  opacity: 1;
}

.hidden {
  display: none;
}

/* Default Animation Styles */
.string,
.body,
.balloon,
.explosion,
.border,
.scorebar {
  animation: none;
}

/* Animation Presets */
.standard-static .balloon,

/* standard-static */
.standard-static .string {
  animation: none;
}

/* standard-floating */
.standard-floating .balloon {
  animation: float 3s linear infinite;
}

.standard-floating .string {
  animation: float 3s linear infinite;
}

/* pop-head */
.pop-head .string {
  animation: string-drop 1.6s linear forwards;
}

.pop-head .balloon {
  animation: balloon-fade-out 0.4s linear forwards;
}

.pop-head .explosion {
  display: inline;
  animation: explode 0.6s linear forwards;
  transform-origin: center;
}

/* Default hidden state */
.scorebar-animation {
  opacity: 0; /* Hidden by default */
}

/* Trigger animation when inside .score-reveal */
.scorebar-reveal .scorebar-animation {
  animation: fade-in 1s linear 0.4s forwards; /* Play fade-in animation */
}

.scorebar-reveal .border {
  animation: fade-out 0.8s linear forwards; /* Play fade-in animation */
}

/* Apply blinking animation to the entire avatar container when waiting for response */
.avatar-container.awaiting-response {
  animation: blink-animation 2s ease-in-out infinite;
  /* Ensure the blinking animation takes precedence over any floating animations */
  animation-fill-mode: both !important;
}
/* Keyframes */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes float {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(16px, -40px) rotate(0deg);
  }
  50% {
    transform: translate(0px, -80px) rotate(0deg);
  }
  75% {
    transform: translate(-16px, -40px) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes string-drop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  5% {
    opacity: 1;
    transform: translateY(-160px) translateX(0px);
  }
  10% {
    opacity: 0.8;
    transform: translateY(-120px) translateX(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(0px) translateX(20px);
  }
}

@keyframes balloon-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes explode {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  90% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.32;
  }
  100% {
    opacity: 1;
  }
}
