.onboard-customize-host {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  justify-content: center;
}

.positioned-audio-wrapper {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.positioned-audio-wrapper.is-speaking {
  opacity: 1; /* Fade in when speaking */
}

.positioned-audio-wrapper .audio-display {
  height: 116px;
  width: 112px;
}

.preview-voice-form-container {
  max-width: 480px;
  width: 88vw;
}

.loader-container {
  margin-top: 16px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 720px) {
  .onboard-customize-host {
    height: calc(
      100vh - 80px
    ); /* Subtract 80px from the full viewport height */
    margin-top: 80px; /* Add the fixed offset from the top */
    justify-content: start;
  }

  .positioned-audio-wrapper .audio-display {
    height: 80px;
    width: 80px;
  }

  .positioned-audio-wrapper .audio-control {
    height: 32px;
    width: 32px;
  }
}
