/* src/components/Button.css */

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.25em;
  background-color: #d0ff00;
  color: black;
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  transition: opacity 0.3s ease;
}

.button:hover {
  opacity: 0.9;
}

.button:disabled {
  background-color: #828282;
  cursor: not-allowed;
}

.button-success {
  background-color: #00ff2f;
}

.button-transparent {
  display: flex; /* Enables flexbox */
  justify-content: center; /* Horizontally centers the text */
  align-items: center; /* Vertically centers the text */
  width: 100%;
  height: 2.25em;
  background-color: transparent;
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  transition: opacity 0.3s ease;
}

/* Hover effect for .button-transparent */
.button-transparent:hover {
  color: #d0ff00;
}
