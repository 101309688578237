/* src/components/GameGridContainer.css */

.game-grid-container {
  width: 100%;
  box-sizing: border-box; /* Include padding in width calculation */
}

.category-title {
  font-size: 2rem;
  text-align: start;
  margin-bottom: 16px;
  position: sticky; /* Keep the title in view when scrolling */
  top: 128px; /* Align with the padding-top of the container */
  background-color: black; /* Match the page's background to prevent overlap issues */
  z-index: 1; /* Ensure it stays above scrollable content */
}

.game-grid-scrollable {
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  display: flex; /* Allows horizontal scrolling */
  width: calc(
    100% - 24px
  ); /* Ensure the scrollable area fits within the container */
  scrollbar-width: none; /* Hide scrollbar Firefox */
  -ms-overflow-style: none; /* Hide scrollbar Internet Explorer 10+ */
}

.game-grid-scrollable::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit-based browsers */
}
