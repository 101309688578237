.user-settings-container {
  position: absolute;
  left: 24px;
  top: 128px;
}

.user-settings-title {
  font-size: 2rem;
}

.user-settings-section {
  font-size: 1.5rem;
  margin-top: 3rem;
}

.user-settings-option {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0px;
  display: block;
  font-size: 1rem;
}

.user-settings-option-signout {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0px;
  display: block;
  font-size: 1rem;
  color: #ff0050;
  cursor: pointer;
}

.user-settings-option-support {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0px;
  display: block;
  font-size: 1rem;
  cursor: pointer;
}

.user-settings-dropdown {
  margin-left: 16px;
  background-color: #000000;
  color: #ffffff;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.button-wrapper-logout .button {
  color: #ff0050;
  font-size: 1rem;
  justify-content: flex-start;
  padding-left: 16px;
}
