/* Game.css */

.game-waiting-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  justify-content: flex-start; /* Align items starting from the top */
  padding-top: 16vh; /* Push content down 16% of the viewport height */
  font-size: 1.5rem; /* Paragraph size */
}
