.about-page {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  color: #ffffff;
  background-color: #000000;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 160px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
}

.about-content-wrapper {
  width: 100%;
  max-width: 88vw;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: none; /* Disable horizontal scrolling */
  scrollbar-width: none; /* Hide scrollbar Firefox */
  -ms-overflow-style: none; /* Hide scrollbar Internet Explorer 10+ */
}

.about-content-wrapper::-webkit-scrollbar {
  display: none;
}

.poppy-intro-wrapper {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.poppy-intro-avatar {
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.poppy-intro-text {
  justify-content: center;
}

.poppy-intro-header {
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 600;
  color: #ffffff;
}

.poppy-intro-subheader {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 400;
  color: #b3b3b3;
}

.about-nav {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  gap: 8px;
  margin-top: 40px;
  margin-bottom: 16px;
  max-width: 960px;
  width: 100%;
}

.nav-button {
  width: 100%;
}

/* Override Button component styles for this context */
.nav-button .button {
  height: 56px;
  font-size: 1.2rem;
}

/* Add border to transparent buttons */
.nav-button .button-transparent {
  border: 1px solid #828282;
}

.nav-button .button-transparent:hover {
  background-color: #d0ff00;
  color: black;
  border: none;
}

.section-contents-wrapper {
  width: 100%;
  max-width: 960px;
  margin: 0;
}

.section-content {
  display: none;
  animation: fadeIn 0.4s ease;
  padding: 2rem;
  background-color: #414141;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.section-content.active {
  display: block;
}

.section-content p {
  font-size: 1.2rem;
  margin: 0;
  white-space: pre-line;
  color: #ffffff;
}

.about-spacer {
  height: 240px;
  width: 100%;
  flex-shrink: 0;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .about-page {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 80px;
  }

  .poppy-intro-avatar {
    width: 120px;
    height: 120px;
  }

  .poppy-intro-header {
    font-size: 2rem;
  }

  .poppy-intro-subheader {
    font-size: 1rem;
  }
  .about-nav {
    margin-top: 24px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  .nav-button .button {
    font-size: 1rem;
  }
  .section-content p {
    font-size: 1rem;
  }
}
