/* Subscribe.css */

.onboarding-progress-container {
  height: 64px;
  width: 64px;
  display: flex; /* Aligns items horizontally */
  align-items: center; /* Centers items vertically */
  position: fixed;
  right: 24px; /* mirror and match the wordmark */
  top: 24px; /* mirror and match the wordmark */
  padding-top: 2.25px; /* mirror and match the wordmark */
  padding-bottom: 2.25px; /* mirror and match the wordmark */
  z-index: 100;
  opacity: 1; /* Start fully visible */
  transition: opacity 0.4s ease-in-out; /* Smooth transition */
}

.onboarding-progress-container.is-speaking {
  opacity: 0; /* Fade to 50% when speaking */
}

@media (max-width: 720px) {
  .onboarding-progress-container {
    height: 32px; /* Adjusted height for mobile screens */
    width: 32px;
    padding-top: 1.12px; /* mirror and match the wordmark */
    padding-bottom: 1.12px; /* mirror and match the wordmark */
  }
}
