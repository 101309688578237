/* TermsAndConditions.css */

.terms-page {
  max-width: 100%;
  height: 100vh; /* Use full viewport height */
  padding-top: 128px; /* Replace margin with padding */
  padding-left: 128px; /* Replace margin with padding */
  padding-right: 128px; /* Replace margin with padding */
  box-sizing: border-box; /* Include padding in height calculation */
}

.terms-and-conditions {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: none; /* Disable horizontal scrolling */
  scrollbar-width: none; /* Hide scrollbar Firefox */
  -ms-overflow-style: none; /* Hide scrollbar Internet Explorer 10+ */
}

/* Hide scrollbar for WebKit-based browsers */
.terms-and-conditions::-webkit-scrollbar {
  display: none;
}

.terms-header h1 {
  color: #d0ff00;
  margin-bottom: 0px;
}

.terms-header p {
  color: #b3b3b3;
  margin-top: 0px;
  margin-bottom: 40px;
}

.terms-and-conditions h2,
.terms-and-conditions h3 {
  color: #ffffff;
}

.terms-and-conditions p,
.terms-and-conditions ul {
  color: #b3b3b3;
  line-height: 1.6;
}

.terms-spacer {
  height: 240px;
}

@media (max-width: 720px) {
  .terms-page {
    padding-top: 80px; /* Replace margin with padding */
    padding-left: 40px; /* Replace margin with padding */
    padding-right: 40px; /* Replace margin with padding */
  }
}

#privacy {
  scroll-margin-top: 128px;
}
