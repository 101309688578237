/* src/pages/GameLibrary.css */

.game-grid-container {
  padding-top: 128px; /* Control the space from the top */
  padding-left: 24px; /* Control space from the left */
}

.game-library-xmark-settings {
  position: fixed; /* Keep the logo in a fixed position */
  right: 24px; /* Align to the right */
  top: 24px; /* Align from the top */
  display: block; /* Prevent inline-block spacing issues */
  z-index: 1000; /* Ensure it stays above other content */
  cursor: pointer;
}
